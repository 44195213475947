@import "@app/styles/vars.scss";

.list {
  width: 100%;
  .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    :global(.form-item) {
      flex-direction: row-reverse;
      gap: 5px;
      align-items: center;
      margin-bottom: 4px;
      :global(.label) {
        margin-bottom: 2px;
      }
    }
    :global(.ant-typography) {
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: $cold-dark-gray;
    }
  }
  .exp {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3B4256;
  }
  .nda {
    display: flex;
    gap: 15px;
  }
  .elements {
    .list_empty {
      background-color: $empty-gray;
      border-radius: 4px;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      text-align: center;
      padding: 5px 0;
      span {
        color: $black-50;
      }
    }
  }
}

.category {
  overflow: hidden;
  background-color: $white;
  border-radius: 4px;
  box-shadow: (0 1px 5px rgba(0, 0, 0, 0.05)), (0px 2px 10px rgba(19, 115, 236, 0.25));
  &:global(.sortable-chosen) {
    box-shadow: 0 21px 20px rgba(29, 125, 232, 0.13);
    border-radius: 4px;
    background-color: $blue-light;
  }
  .panel {
    &__content {
      display: flex;
      align-items: center;
      width: 100%;
    }
    :global(.ant-collapse-header) {
      display: flex;
      align-items: center;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05), 0 2px 10px rgba(19, 115, 236, 0.25);
      padding: 10px;
      :global(svg.handle) {
        width: 24px;
        height: 24px;
        &:hover {
          cursor: grabbing;
        }
      }
    }
    :global(.ant-collapse-arrow) {
      //transform: rotate(90deg);
      svg {
        color: $cold-gray;
      }
    }
    :global(.ant-collapse-header[aria-expanded="true"]) {
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05), 0 2px 10px rgba(19, 115, 236, 0.25);
      border-radius: 4px 4px 0 0;
      transition: 0.3s ease-in-out all;
      :global(.ant-collapse-arrow) {
        transform: rotate(180deg);
        top: 38%;
      }
    }
  }
  .action {
    margin-right: 20px;
  }
  &:global(.isDragging) {
    height: 52px;
  }
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  :global(.ant-dropdown-trigger) {
    display: flex;
    margin-right: 5px;
  }
}

.header {
  display: flex;
  width: 100%;
  &__name {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $text;
    flex: 2;
  }

  &__period {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text;
    margin-right: 20px;
    flex: 1;
  }
}
.dragIcon {
  color: $platina;
  margin-right: 5px;
}

.project {
  .description, .team, .position, .roles, .tasks, .technologies {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $cold-dark-gray;
    border-bottom: 1px solid #D5DEE9;
    padding: 8px 0;
    display: grid;
    grid-template-columns: 1fr 3fr;
    span {
      color: $cold-gray;
      margin-right: 10px;
    }
  }
  .technologies {
    border: none;
  }
}